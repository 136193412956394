<footer class="pt-9">
  <div class="footer-container flex justify-between pb-7">
    <section>
      <ul>
        <h5 class="pb-4">{{ ('contact' | translate) | uppercase }}</h5>
        <li>
          <address class="pb-7 flex g-4 direction-col">
            <p class="address-title">{{ 'headquarters' | translate }}</p>
            <p>{{ 'sintegra.street' | translate }}</p>
            <p>Pirajá</p>
            <p>17.580-419</p>
            <p>{{ 'sintegra.country' | translate }}</p>
          </address>
        </li>
        <li>
          <address class="flex g-4 direction-col">
            <p class="address-title">{{ 'branch' | translate }}</p>
            <p>Av. Brigadeiro Luis Antônio, 2503</p>
            <p>Jardim Paulista</p>
            <p>01.402-000</p>
            <p>{{ 'sintegra.country' | translate }}</p>
          </address>
        </li>
      </ul>
    </section>
    <section>
      <div class="flex g-4 direction-col">
        <h5>SINTEGRA SURGICAL</h5>
        <a>{{ 'products' | translate }}</a>
        <a>{{ 'updates' | translate }}</a>
        <a>{{ 'events' | translate }}</a>
        <a>{{ 'corporate' | translate }}</a>
        <a>{{ 'support' | translate }}</a>
      </div>
    </section>
    <section class="flex g-4 direction-col">
      <h5>{{ ('terms.user' | translate) | uppercase }}</h5>
      <a>{{ 'privacy.policy' | translate }}</a>
      <a>{{ 'cookies.policy' | translate }}</a>
      <a>{{ 'legal.warning' | translate }}</a>
      <a>{{ 'terms.user' | translate }}</a>
    </section>
    <section class="flex direction-col">
      <img class="jacto-logo" src="../../../assets/images/logo-jactogroup-with-icon.svg" alt="Jacto Group Logo">
      <a class="pb-4">{{ 'about.jacto' | translate }}</a>
      <a>{{ 'work.us' | translate }}</a>
    </section>
  </div>
  <div class="footer-container flex pb-7">
    <img class="icon-20 me-4" src="../../../assets/images/icons/help-outlinesvg.svg" alt="Help icon">
    <p class="pe-2">{{ 'any.doubts' | translate }}</p>
    <a class="bold-600">{{ 'get.help' | translate }}</a>
  </div>

  <div class="footer-container mobile flex wrap items-center justify-between">
    <div class="mobile flex" style="border-bottom: 1px solid var(--color-neutral-30, rgba(72, 70, 73, 1));width: 100%;
    justify-content: space-between; padding-bottom: 22px;">
      <img class="sintegra-logo" src="../../../assets/images/logo-with-name-bw.svg" alt="Sintegra logo">
      <div class="flex mobile direction-col g-3">
        <h5>{{ 'follow' | translate }}:</h5>
        <div class="flex mobile items-center justify-between">
          <img class="icon-24" src="../../../assets/images/icons/whatsapp.svg" alt="Facebook icon">
          <img class="icon-24" src="../../../assets/images/icons/instagram.svg" alt="Instagram icon">
          <img class="icon-24" src="../../../assets/images/icons/facebook.svg" alt="Facebook icon">
          <img class="icon-24" src="../../../assets/images/icons/linkedin.svg" alt="Linkedin icon">
        </div>
      </div>
    </div>
    <p class="pt-4">Copyright © 2024 Síntegra. All rights reserved.</p>
  </div>
</footer>

<div class="container flex direction-col g-14 pb-14">
  <app-main></app-main>
  <section class="procedures flex direction-col g-6">
    <h3 class="title">{{ 'surgical.procedures' | translate }}</h3>
    <p class="subtitle">{{ 'whats.new' | translate }}</p>
    @if (isLoaded) {
    <ion-grid>
      <ion-row>
        @for (item of videos; track $index) {
        <ion-col size="1" size-xs="4" size-sm="4" size-md="2" size-lg="2" class="pt-4" class="list">
          <li>
            <app-card-shorts [item]="item" [routerLink]="['/procedures', item.id]"></app-card-shorts>
          </li>
        </ion-col>
        }
      </ion-row>
    </ion-grid>
    <button class="more flex items-center self-end mt-8" [routerLink]="['/procedures']">
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    } @else {
    <div class="g-6 pb-3 skeleton-desktop">
      <ion-skeleton-text [animated]="true"
        style="min-height: 460px; min-width: 220px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 460px; min-width: 220px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 460px; min-width: 220px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 460px; min-width: 220px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 460px; min-width: 220px; border-radius: 8px;"></ion-skeleton-text>
    </div>
    <div class="g-5 pb-3 skeleton-mobile">
      <ion-skeleton-text [animated]="true"
        style="min-height: 240px; min-width: 114px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 240px; min-width: 114px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 240px; min-width: 114px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 240px; min-width: 114px; border-radius: 8px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"
        style="min-height: 240px; min-width: 114px; border-radius: 8px;"></ion-skeleton-text>
    </div>
    <button class="more flex items-center self-end transparent" disabled>
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    }
  </section>
  <section class="news flex direction-col g-6">
    <h3 class="title">{{ 'news' | translate }}</h3>
    <p class="subtitle">{{ 'whats.new' | translate }}</p>
    @if (isLoaded) {
    <ul class="flex g-6 pb-3 news-ul">
      <app-card-news class="first-li" [item]="news[0]" size="xlg" [routerLink]="['/news', news[0].id]"></app-card-news>
      <div class="flex direction-col">
        @for (n of news.slice(1); track $index) {
        <li>
          <app-card-news [item]="n" [routerLink]="['/news', n.id]"></app-card-news>
        </li>
        }
      </div>
    </ul>
    <button class="more flex items-center self-end" [routerLink]="['/news']">
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    } @else {
    <ul class="g-6 pb-3 news-ul skeleton-desktop">
      <li class="first-li">
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 443px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100px; height: 16px; border-radius: 4px; margin: 6px 0;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 250px; height: 24px; border-radius: 4px; margin-bottom: 6px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 150px; height: 16px; border-radius: 4px;"></ion-skeleton-text>
      </li>
      <div class="flex direction-col w-full g-6">
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 96px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 96px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 96px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 96px; border-radius: 8px;"></ion-skeleton-text>
      </div>
    </ul>
    <ul class="g-4 pb-3  direction-col skeleton-mobile">
      <li class="first-li">
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 230px; border-radius: 16px;"></ion-skeleton-text>
      </li>
      <div class="flex direction-col w-full g-4">
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 80px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 80px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 80px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 100%; height: 80px; border-radius: 8px;"></ion-skeleton-text>
      </div>
    </ul>
    <button class="more flex items-center self-end transparent" disabled>
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    }
  </section>
  <section class="categories" style="position: relative;">
    @if (isLoaded) {
    <div class="our-products flex direction-col g-6 pb-14">
      <h3 class="title pt-9">{{ 'our.products.home' | translate }}</h3>
      <p class="category-title">{{ selectedCat.title }}</p>
      <p class="description">{{ selectedCat.description }}</p>
      <button class="flex items-center" [routerLink]="'/products'">
        {{ 'our.products.more' | translate }}
        <ion-icon name="arrow-forward-outline" class="icon-24 ps-3"></ion-icon>
      </button>
    </div>
    <ion-grid>
      <ion-row>
        @for (item of categories; track $index) {
        <ion-col size="1" size-xs="4" size-sm="4" size-md="2" size-lg="2" class="pt-4" class="list">
          <li class="category flex direction-col" [ngClass]="{grow: selectedCat.id === categories[$index].id}"
            (click)="selectCategory(item)">
            <img [src]="item.picture" alt="Product picture">
            <p>{{ item.title }}</p>
          </li>
        </ion-col>
        }
      </ion-row>
    </ion-grid>
    <div class="circle">
      <img class="selected-img fade-in-image" #categoryBackground [src]="selectedCat.picture" alt="Category image">
    </div>
    } @else {
    <div class="skeleton-desktop direction-col">
      <div class="flex direction-col g-6">
        <h3 class="title">{{ 'our.products' | translate }}</h3>
        <ion-skeleton-text [animated]="true"
          style="width: 250px; height: 34px; border-radius: 4px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 150px; height: 60px; border-radius: 4px;"></ion-skeleton-text>
        <button class="flex items-center" disabled>
          {{ 'our.products.more' | translate }}
          <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
        </button>
      </div>
      <div class="flex g-7 mt-4">
        <ion-skeleton-text [animated]="true"
          style="width: 180px; height: 180px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 180px; height: 180px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 180px; height: 180px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 180px; height: 180px; border-radius: 16px;"></ion-skeleton-text>
      </div>
    </div>
    <div class="skeleton-mobile direction-col">
      <div class="flex direction-col g-4">
        <h3 class="title">{{ 'our.products' | translate }}</h3>
        <ion-skeleton-text [animated]="true"
          style="width: 250px; height: 24px; border-radius: 4px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 150px; height: 30px; border-radius: 4px;"></ion-skeleton-text>
        <button class="flex items-center" disabled>
          {{ 'our.products.more' | translate }}
          <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
        </button>
      </div>
      <div class="flex g-4 mt-3 wrap">
        <ion-skeleton-text [animated]="true"
          style="width: 110px; height: 110px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 110px; height: 110px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 110px; height: 110px; border-radius: 16px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 110px; height: 110px; border-radius: 16px;"></ion-skeleton-text>
      </div>
    </div>
    }
  </section>
  <section class="events flex direction-col g-6">
    <h3 class="title">{{ 'next.events' | translate }}</h3>
    <p class="subtitle">{{ 'next.events.check' | translate }}</p>
    @if (isLoaded) {
    <ul class="flex g-6 pb-3 news-ul">
      <div class="flex g-6 events-ul">
        @for (e of events; track $index) {
        <li>
          <app-card-event [item]="e" [routerLink]="['/events', e.id]"></app-card-event>
        </li>
        }
      </div>
    </ul>
    <button class="more flex items-center self-end" [routerLink]="['/events']">
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    } @else {
    <ul class="flex g-6 pb-3 news-ul skeleton-desktop">
      <div class="flex g-6">
        <ion-skeleton-text [animated]="true"
          style="width: 325px; height: 244px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 325px; height: 244px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 325px; height: 244px; border-radius: 8px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 325px; height: 244px; border-radius: 8px;"></ion-skeleton-text>
      </div>
    </ul>
    <ul class="g-6 pb-3 skeleton-mobile">
      <ion-skeleton-text [animated]="true" style="width: 100%; height: 144px; border-radius: 8px;"></ion-skeleton-text>
    </ul>
    <button class="more flex items-center self-end transparent" disabled>
      {{ 'see.more' | translate }}
      <ion-icon name="arrow-forward-outline" class="ps-4"></ion-icon>
    </button>
    }
  </section>
  <section class="geography flex g-13 justify-center">
    <div class="flex direction-col items-center g-7 py-11">
      <p class="number">21</p>
      <p class="info">{{ 'countries' | translate }}</p>
    </div>
    <div class="flex direction-col items-center g-7 py-11">
      <p class="number">4</p>
      <p class="info">{{ 'continents' | translate }}</p>
    </div>
    <div class="flex direction-col items-center g-7 py-11">
      <p class="number">21</p>
      <p class="info">{{ 'years' | translate }}</p>
    </div>
    <div class="flex direction-col items-center g-7 py-11">
      <p class="number">+80</p>
      <p class="info">{{ 'products' | translate }}</p>
    </div>
  </section>
  <section class="mobile flex pt-12">
    <div class="flex g-6 direction-col justify-center">
      <h3>{{ 'app.discover' | translate }}</h3>
      <p>{{ 'app.more' | translate }}</p>
      <p class="pt-5">{{ 'app.disponibility' | translate }}</p>
      <div class="stores">
        <button>
          <img src="../../../assets/images/store-applesvg.svg" alt="Apple Store">
        </button>
        <button>
          <img src="../../../assets/images/store-playsvg.svg" alt="Play Store">
        </button>
      </div>
    </div>
    <img class="mobiles" src="../../../assets/images/mobiles.svg" alt="Mobile">
  </section>
  <section class="help py-14 flex g-9 direction-col items-center">
    <p class="help-title">{{ 'have.doubts' | translate }}</p>
    <p class="help-subtitle">{{ 'access' | translate }}<a>{{ 'help.channel' | translate }}</a></p>
  </section>
</div>

import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', loadChildren: () => import('./pages/login/login.routes').then(r => r.LOGIN_ROUTES) },
  { path: 'procedures', loadChildren: () => import('./pages/procedures/procedures.routes').then(r => r.PROCEDURES_ROUTES) },
  { path: 'news', loadChildren: () => import('./pages/news/news.routes').then(r => r.NEWS_ROUTES) },
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.routes').then(r => r.FAVORITES_ROUTES) },
  { path: 'products', loadChildren: () => import('./pages/products/products.routes').then(r => r.PRODUCT_ROUTES) },
  { path: 'events', loadChildren: () => import('./pages/events/events.routes').then(r => r.EVENTS_ROUTES) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.routes').then(r => r.PROFILE_ROUTES) },
  { path: 'search', loadChildren: () => import('./pages/search/search.routes').then(r => r.SEARCH_ROUTES) },
  { path: 'corporate', loadChildren: () => import('./pages/company/company.routes').then(r => r.COMPANY_ROUTES) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

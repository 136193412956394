<article class="h-full cursor-pointer">
  <div class="shorts-container flex justify-center items-center h-full w-full">
    <ion-icon class="icon-18 play" name="play"></ion-icon>
    <img class="h-full w-full" [src]="highlight">
  </div>
  <div class="flex justify-between items-center pe-3">
    <p class="shorts-title pt-3 w-full">{{ item.title }}</p>
    @if (urlService.urlContains('/favorites')) {
    <ion-icon class="star pt-3" name="star" (click)="unfavorite($event)"></ion-icon>
    }
  </div>
</article>

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { IonButtons, IonContent, IonHeader, IonIcon, IonMenu, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar, IonButton, IonSearchbar } from '@ionic/angular/standalone';
import { filter } from 'rxjs';
import { search, chevronForward, close, arrowBack } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MeService } from './services/api/ws-user/me/me.service';
import { AuthService } from './services/api/ws-user/auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [IonButton, RouterOutlet, NavbarComponent, FooterComponent, IonContent, IonHeader, IonButtons, IonToolbar, IonMenuButton, IonTitle, IonMenu, IonIcon, RouterModule, TranslateModule, IonMenuToggle, CommonModule, IonSearchbar],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  user!: any;
  searchQuery: string = '';
  inputVisible: boolean = false;
  theme = 'light';
  @ViewChild('searchbar', { static: false }) searchbar!: IonSearchbar;
  @ViewChild(IonContent) private ionContent!: IonContent;

  showFooter: boolean = true;
  subMenu: boolean = false;

  window!: Element;
  constructor(private router: Router, private meService: MeService, private authService: AuthService, private renderer: Renderer2) {
    addIcons({ search, chevronForward, close, arrowBack });
  }
  ngOnInit(): void {
    this.scrollToTop();
    this.getMe();
  }

  private getMe() {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r.data;
          this.renderer.setAttribute(document.body, 'data-theme', this.user.theme);
          localStorage.setItem('mode', this.user.theme);
        }
      });
    } else {
      this.authService.getLoginObservable().subscribe({
        next: () => {
          this.meService.meSubject$.subscribe({
            next: (r) => {
              this.user = r.data;
              this.renderer.setAttribute(document.body, 'data-theme', this.user.theme);
              localStorage.setItem('mode', this.user.theme);
            }
          });
        }
      });

      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.setItem('mode', 'dark');
        this.renderer.setAttribute(document.body, 'data-theme', 'dark');
      } else {
        localStorage.setItem('mode', 'light');
        this.renderer.setAttribute(document.body, 'data-theme', 'light');
      }
    }
  }

  private scrollToTop() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => {
        if (this.router.getCurrentNavigation()?.extras?.state?.['scrollTop'] !== false) {
          // console.log((await this.ionContent.getScrollElement()).scrollTop);
          await this.ionContent.scrollToTop();
        }
      });
  }

  showSearchInput() {
    this.inputVisible = true;
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 500);
  }

  hideSearchInput() {
    this.inputVisible = false;
    this.searchQuery = '';
    this.searchbar.value = null;
  }

  search(e: any) {
    this.router.navigate(['search'], { queryParams: { query: e.target.value } });
  }

  closeMenu() {
    this.subMenu = false;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  throwError,
  of
} from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/api/ws-user/auth/auth.service';
import { TokenStorageService } from '../../services/token/token-storage.service';
import { MeService } from '../../services/api/ws-user/me/me.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private token: string | undefined;
  private lang: string | undefined;
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private authService: AuthService, private tokenService: TokenStorageService, private route: Router, private meService: MeService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): any {
    if (this.pathExcludes(request)) {
      return next.handle(request);
    }

    this.token = this.tokenService.getJwt()!;
    this.lang = localStorage.getItem('lang') ?? 'pt-BR';

    request = this.addHeader(request, this.lang, this.token);

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status === 401 && error.error.errors[0] === "TokenExpired") {
          // const lang = this.translateService.getCurrentLang() ?? 'pt-BR';
          return this.handleTokenRefresh(next, request);
        } else if (error.status === 403) {
          this.route.navigate(['/login']);
          return throwError(() => new Error(error.error.errors[0] || "Forbiden"));
        } else {
          if (request.url.includes('favorite')) {
            this.route.navigate(['/login']);
          }

          const errorMsg = error.error.errors[0] || "Unknown Error Occurred";
          // this.snackBar.open(error.error.errors[0], this.translate.instant('close'), { duration: 5000 });
          return throwError(() => new Error(errorMsg));
        }
      })
    );
  }

  private pathExcludes(request: HttpRequest<unknown>) {
    const pathEndsExcludes: string[] = [
      '/auth',
      '/auth/verify-email',
      '/auth/forgot-password',
      '/auth/check-password-recovery',
      '/auth/refresh-token',
      '/auth/password-recovery'
    ];

    const pathIncludesExcludes: string[] = [
      '/assets/i18n',
    ];

    return pathEndsExcludes.some(path => request.url.endsWith(path)) ||
      pathIncludesExcludes.some(path => request.url.includes(path));
  }

  private addHeader(request: HttpRequest<unknown>, lang: string, jwt: string | null) {
    let headers: { [key: string]: string } = {
      'Accept-Language': lang,
      'Content-Type': 'application/json'
    };

    if (jwt) {
      headers['Authorization'] = `Bearer ${jwt}`;
    }

    return request.clone({
      setHeaders: headers
    });
  }

  private handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');

      return this.authService.refreshToken(this.tokenService.getRefresh()).pipe(
        switchMap((newToken: any) => {
          this.tokenService.removeJwt();

          if (newToken.data?.jwtToken) {
            this.tokenService.setJwt(newToken.data.jwtToken);
            this.tokenService.setRefresh(newToken.data.refreshToken);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(newToken.data.jwtToken);

            return next.handle(this.addHeader(request, this.lang || 'pt-BR', newToken.data.jwtToken));
          }
          this.isRefreshing = false;
          this.refreshTokenSubject.next('');
          this.route.navigate(['/login']);

          return throwError(() => new Error('No JWT in refresh token response, redirecting to login'));
        }),
        catchError((_) => {
          this.isRefreshing = false;
          this.tokenService.removeJwt();
          // this.snackBar.open(this.translate.instant('login.redirect'), this.translate.instant('close'), { duration: 5000 });
          this.meService.updateCache(null);
          return throwError(() => new Error('Failed to refresh token, redirecting to login'));
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter((token: string) => token != ''),
        take(1),
        switchMap((jwt: string) => {
          this.tokenService.setJwt(jwt);
          return next.handle(this.addHeader(request, this.lang || 'pt-BR', jwt));
        })
      );
    }
  }
}

<!-- <app-navbar></app-navbar> -->
<ion-menu contentId="main-content" (ionDidClose)="closeMenu()">
  <ion-header class="ion-no-border">
    <ion-toolbar class="px-4">
      @if (!subMenu) {
      <ion-menu-toggle>
        <ion-icon class="close" name="close"></ion-icon>
      </ion-menu-toggle>
      } @else {
      <ion-icon class="arrow" (click)="subMenu = !subMenu" slot="start" name="arrow-back"></ion-icon>
      <p>{{ 'back' | translate }}</p>
      }
    </ion-toolbar>
  </ion-header>
  <!-- <ion-content class="ion-padding">This is the menu content.</ion-content> -->
  <div class="px-4" style="height: 100%;">
    @if (!subMenu) {
    <ul class="flex direction-col g-6">
      <li>
        <ion-menu-toggle>
          <a routerLink="/home">{{ 'home' | translate }}</a>
        </ion-menu-toggle>
      </li>
      <li>
        <ion-menu-toggle>
          <a routerLink="/products">{{ 'products' | translate }}</a>
        </ion-menu-toggle>
      </li>
      <li>
        <a (click)="subMenu = !subMenu" class="flex items-center justify-between">{{ 'content' | translate }}
          <ion-icon name="chevron-forward"></ion-icon>
        </a>
      </li>
      <li>
        <ion-menu-toggle>
          <a routerLink="/events">{{ 'events' | translate }}</a>
        </ion-menu-toggle>
      </li>
      <li>
        <ion-menu-toggle>
          <a routerLink="/corporate">{{ 'corporate' | translate }}</a>
        </ion-menu-toggle>
      </li>
      @if (user) {
      <li>
        <ion-menu-toggle>
          <a routerLink="/favorites">{{ 'favorites' | translate }}</a>
        </ion-menu-toggle>
      </li>
      }
    </ul>
    @if (!user) {
    <ion-menu-toggle class="pt-5 pb-5 flex justify-between">
      <button class="login" [routerLink]="['/login']" routerLinkActive="router-link-active">{{ 'login' |
        translate }}</button>
      <button class="register" [routerLink]="['/login/register']" routerLinkActive="router-link-active">{{ 'register'
        | translate }}</button>
    </ion-menu-toggle>
    }
    } @else {
    <ul class="flex direction-col g-6">
      <li>
        <ion-menu-toggle>
          <a (click)="subMenu = !subMenu" routerLink="/news">{{ 'news' | translate }}</a>
        </ion-menu-toggle>
      </li>
      <li>
        <ion-menu-toggle>
          <a (click)="subMenu = !subMenu" routerLink="/procedures">{{ 'procedures' | translate }}</a>
        </ion-menu-toggle>
      </li>
    </ul>
    }
  </div>
</ion-menu>
<div class="ion-page" id="main-content">
  <ion-content>
    <ion-header style="position: sticky; top: 0;">
      <ion-toolbar>
        <div class="mobile-menu">
          <div class="flex justify-between pe-4 mx-3" [ngStyle]="{display: inputVisible ? 'none' : 'flex'}">
            <ion-buttons slot="start">
              <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <img class="logo" src="../assets/images/logo-only-blue-sintegra.svg" alt="" [routerLink]="'/'">
            <div class="flex items-center g-7">
              <ion-icon class="search" name="search" (click)="showSearchInput(); searchbar.setFocus();"></ion-icon>

              @if (user) {
              <img class="avatar" [src]="user.persons[0].picture" alt="User avatar"
                onerror="this.src='../../../assets/images/user.png'" [routerLink]="'/profile'">
              }
            </div>
          </div>
          <ion-searchbar #searchbar class="custom" mode="ios" inputmode="search" [placeholder]="'search' | translate"
            (keyup.enter)="search($event)" [ngStyle]="{display: inputVisible ? 'block' : 'none'}"
            (focusout)="hideSearchInput()"></ion-searchbar>
        </div>
        <app-navbar></app-navbar>
      </ion-toolbar>
    </ion-header>
    <!-- <div class="height"> -->
      <router-outlet></router-outlet>
    <!-- </div> -->
    @if (showFooter) {
    <app-footer></app-footer>
    }
  </ion-content>
</div>

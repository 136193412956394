import { Component } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { playCircle, call, mail, locationSharp, helpCircleOutline, chevronDown } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [IonIcon, TranslateModule],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {

  constructor() {
    addIcons({ playCircle, call, mail, locationSharp, helpCircleOutline, chevronDown });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "end" });
  }

}

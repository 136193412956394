<nav>
  <div class="nav flex wrap items-center g-9 justify-between">
    <div class="flex items-center g-9">
      <img src="../../../assets/images/logo-with-name.svg" alt="Sintegra Logo" routerLink="/home">
      <ul class="flex g-5">
        <li>
          <a routerLink="/home">{{ 'home' | translate }}</a>
        <li>
          <a routerLink="/products">{{ 'products' | translate }}</a>
        <li>
          <a class="flex g-3" (click)="presentPopover($event)">
            <p #popoverTrigger>{{ 'updates' | translate }}</p>
            <ion-icon name="caret-down-outline"></ion-icon>
          </a>
          <ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
            <ng-template>
              <ion-content class="ion-padding">
                <ul class="flex direction-col g-5">
                  <li class="submenu-item cursor-pointer" routerLink="/procedures" (click)="isOpen = false">{{
                    'surgical.procedures' |
                    translate }}</li>
                  <li class="submenu-item cursor-pointer" routerLink="/news" (click)="isOpen = false">{{ 'news' |
                    translate }}</li>
                </ul>
              </ion-content>
            </ng-template>
          </ion-popover>
        <li>
          <a routerLink="/events">{{ 'events' | translate }}</a>
        <li>
          <a routerLink="/corporate">{{ 'corporate' | translate }}</a>
        <li>
          @if (user) {
          <a routerLink="/favorites">{{ 'favorites' | translate }}</a>
          }
      </ul>
    </div>
    <div class="flex items-center g-9">
      <ion-searchbar class="custom" mode="ios" inputmode="search" [placeholder]="'search' | translate"
        (keyup.enter)="search($event)"></ion-searchbar>
      <div class="buttons flex items-center g-6">
        @if (!user && isLoaded) {
        <button class="login" [routerLink]="['/login']" routerLinkActive="router-link-active">{{ 'login' |
          translate }}</button>
        <button class="register" [routerLink]="['/login/register']" routerLinkActive="router-link-active">{{ 'register'
          | translate }}</button>
        }
        @if (user && isLoaded) {
        <img class="avatar" [src]="user.data.persons[0].picture" alt="User avatar"
          onerror="this.src='../../../assets/images/user.png'" [routerLink]="'/profile'">
        }
      </div>
    </div>
  </div>
</nav>
